<template>
  <div class="col wrapper_for_printing">
    <div
      id="while_printing_the_report"
      class="while_printing_the_report"
      :class="{ reverse_dir: getLocale == 'en' }"
    >
      <div class="information">
        <div class="part_one">
          <div class="parts">
            <span>:{{ $t("admin_dashboard_type.name") }}</span>
            <span class="when_name">{{ userDataForPrintTwo.name }}</span>
          </div>
          <div class="parts">
            <span>:{{ $t("gender") }}</span>
            <span class="when_name">
              {{
                userDataForPrintTwo.gender == 1
                  ? $t("admin_dashboard_type.male")
                  : $t("admin_dashboard_type.female")
              }}</span
            >
          </div>
          <div class="parts">
            <span>:{{ $t("admin_dashboard_type.age") }}</span>
            <span class="when_name">{{ userDataForPrintTwo.age }}</span>
          </div>
          <div class="parts">
            <span>:{{ $t("admin_dashboard_type.class_name") }}</span>
            <span class="when_name">{{ userDataForPrintTwo.group_name }}</span>
          </div>
          <div class="parts spans">
            <div class="is_span">
              <span>{{ $t("admin_dashboard_type.pre") }}</span>
              <span
                class="border_span"
                :class="{ pre: $route.params.exam_type_two == 3 }"
              ></span>
            </div>
            <div class="is_span ml-4">
              <span>{{ $t("admin_dashboard_type.post") }}</span>
              <span
                class="border_span"
                :class="{ post: $route.params.exam_type_two == 4 }"
              ></span>
            </div>
          </div>
          <div class="parts">
            <span>:{{ $t("admin_dashboard_type.Total time") }}</span>
            <span class="when_name"
              >{{ userDataForPrintTwo.total_time / 60 }}
            </span>
            <span>minute</span>
          </div>
          <div class="parts">
            <!-- <span>:{{ $t("admin_dashboard_type.Total inputs/time") }}</span> -->
            <span class="when_name">{{ userDataForPrintTwo.time_count }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="is_old_wrapper">
        <div class="hello_test">
          <div
            class="wrapper_of_images"
            v-for="(item, index) in printPdfReportData.answers"
            :key="index"
          >
            <div class="is_items_with_checkbox">
              <div class="is_is_checker">
                <span>{{ item.original ? "Original" : "Original" }}</span>
                <el-checkbox v-model="item.original"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.flex ? "Flex" : "Flex" }}</span>
                <el-checkbox v-model="item.flex"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.flue ? "Flue" : "Flue" }}</span>
                <el-checkbox v-model="item.flue"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.details ? "Details" : "Details" }}</span>
                <el-checkbox v-model="item.details"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.imagination ? "Imagination" : "Imagination"
                }}</span>
                <el-checkbox v-model="item.imagination"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.title ? "Title" : "Title" }}</span>
                <el-checkbox v-model="item.title"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.detailed_title ? "Detailed Title" : "Detailed Title"
                }}</span>
                <el-checkbox v-model="item.detailed_title"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.meaningful ? "Meaningful" : "Meaningful" }}</span>
                <el-checkbox v-model="item.meaningful"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.reject ? "Reject" : "Reject" }}</span>
                <el-checkbox v-model="item.reject"></el-checkbox>
              </div>
            </div>
            <div>
              <div class="wrapper_two">
                <div class="loop_here" v-for="(img, i) in item.object" :key="i">
                  <img
                    :style="{ transform: `rotate(${img.rotation}deg)` }"
                    class="item"
                    :src="img.img_path"
                    alt=""
                  />
                </div>
              </div>
              <div class="text_images">
                <span>({{ index + 1 }})</span>
                <span>{{ item.answer }}</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div
      @click="handlerSubmitAnswers"
      class="sharing_wrapper for_printing_buttons"
      v-if="printPdfReportData.answers.length > 0"
    >
      <span class="">{{ $t("send") }}</span>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      checkboxData: [
        "Original",
        "Flex",
        "Flue",
        "Details",
        "Imagination",
        "Title",
      ],
      activate: [],
      testData: {},
    };
  },
  mounted() {
    this.printExamReport();
  },
  computed: {
    printPdfReportData() {
      return this.$store.getters["groups/printPdfReportDataNumberTwo"];
    },
    userDataForPrintTwo() {
      return this.$store.getters["groups/userDataForPrintTwo"];
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    printExamReport() {
      let exData = {
        student_id: this.$route.params.student_id,
        exam_type: this.$route.params.exam_type_two,
        challenge_no: this.$route.params.challenge_no,
      };

      this.$store.dispatch("groups/handlerPrintExamReportForMark", exData);
      // .then((res) => {
      //   console.log(res);
      // });
    },
    handlerSubmitAnswers() {
      let allData = this.printPdfReportData.answers;
      let answersCorrection = [];
      allData.forEach((element) => {
        answersCorrection.push({
          id: element.id,
          original: element.original,
          flex: element.flex,
          flue: element.flue,
          details: element.details,
          imagination: element.imagination,
          title: element.title,
          meaningful: element.meaningful,
          detailed_title: element.detailed_title,
          reject: element.reject,
        });
      });

      this.$store
        .dispatch("groups/handlerSubmitExamReportForMark", {
          answers: answersCorrection,
        })
        .then(() => {
          Swal.fire({
            text: "تم الارسال بنجاح",
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: "موافق",
            buttonsStyling: false,
          });
          this.printExamReport();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sharing_wrapper {
  width: fit-content;
  min-width: 150px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
.while_printing_the_report {
  background: #fff;
  padding: 20px;
  direction: ltr;
  &.reverse_dir {
    direction: rtl;
  }
}
.is_old_wrapper {
  display: flex;
  justify-content: center;
}
.second_elements {
  width: 200px;
  background: #fff;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .all_marks {
    font-weight: bold;
    .every_mark {
      margin-bottom: 20px;
      .box_title {
        text-align: center;
        margin-bottom: 7px;
      }
      .box_border {
        width: 70px;
        height: 70px;
        border: 2px solid #000;
        margin: auto;
      }
    }
  }
}
.hello_test {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  // padding: 30px 0;
  // margin: auto;
  background: #fff;
  margin-bottom: 30px;
  flex-direction: row-reverse;
  justify-content: center;
}
.wrapper_of_images {
  display: flex;
  // align-items: center;
  gap: 15px;
  // margin-bottom: 25px;
  .wrapper_two {
    width: 200px;
    background: #fff;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    .loop_here {
      width: 40px;
      height: 40px;
      .item {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.is_items_with_checkbox {
  .is_is_checker {
    display: flex;
    justify-content: end;
    gap: 8px;
    // margin-bottom: 5px;
  }
}
.text_images {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 6px;
}
.information {
  display: flex;
  flex-direction: row-reverse;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  .part_one {
    // background: #ccc;
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    .parts {
      display: flex;
      flex-direction: row-reverse;
      width: 50%;
      margin-bottom: 15px;
      &.spans {
        // display: flex;
        gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
      .when_name {
        margin: 0 5px;
        color: #555;
      }
    }
  }
  .part_two {
    .every {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 8px;
      .is_name {
        min-width: 100px;
      }
      .item_border {
        border: 1px solid #000;
        width: 40px;
        height: 37px;
        &.date {
          width: 120px;
        }
      }
    }
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
    // page-break-after: avoid;
    // page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    // background: red;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    // background: green;
    page-break-after: avoid;
    page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    // background: green;
    page-break-after: avoid;
    page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    margin-bottom: 0px;
  }
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
</style>
